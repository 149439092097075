<template>
  <div class="zone">
    <TJDetailTitle :titleData="'用量统计_医院'" />
    <TJDetailMenu :dataList="dataList" />

    <div class="diagram" v-loading.lock="fullscreenLoading">
      <div class="diagrammax">时序分析图</div>
      <sxfxLineEchart :id="'sxfxLineEchart'" :ehartData="ehartData" />
    </div>
  </div>
</template>

<script>
import api from '@/api/tongji'
import sxfxLineEchart from '@/components/sxfxLineEchart.vue'
import TJDetailMenu from '@/components/TJDetailMenu'
import TJDetailTitle from '@/components/TJDetailTitle'
export default {
  name: 'ReportsDetail',
  components: { sxfxLineEchart, TJDetailMenu, TJDetailTitle },
  data() {
    return {
      fullscreenLoading: false, //加载中
      dataList: [
        {
          name: '省份',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '医院',
          value: '',
          width: '33%',
          bottomBorder: false,
        },
        {
          name: '时间',
          value: '',
          status: '',
          width: '33%',
          bottomBorder: false,
        },
      ],

      query: {
        platform_id: '', //平台标识
        province_id: '', //省份标识
        hospital_id: '', //医院标识

        province_name: '',

        start_stats_year: '', //开始年份 例如:2021
        end_stats_year: '', //结束年份
        start_stats_month: '', //开始月份 例如:202002
        end_stats_month: '', //结束月份
        start_stats_day: '', //开始日期 例如:20210304
        end_stats_day: '', //结束日期
        date_type: '', //日期类型:0:年,1,月,2,日
      },

      ehartData: {},
    }
  },
  created() {
    this.query = this.$route.query
    this.dataList[2].status = this.query.date_type
  },
  mounted() {
    this.hospital_used_day_timingFun()
  },
  methods: {
    // 省份销量统计构成分析
    hospital_used_day_timingFun() {
      this.fullscreenLoading = true
      let param = {
        province_id: this.query.province_id, //省份标识
        hospital_id: this.query.hospital_id, //医院标识

      }

      this.query.platform_id != ''
        ? (param.platform_id = this.query.platform_id)
        : '' //平台标识
      let start_day,end_day;
      if (this.query.date_type == '年') {
        param.start_stats_year = start_day = this.query.start_stats_year
        param.end_stats_year = end_day = this.query.end_stats_year
        this.dataList[2].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_year,
          this.query.end_stats_year,
        )
      } else if (this.query.date_type == '月') {
        param.start_stats_month = start_day = this.query.start_stats_month
        param.end_stats_month = end_day = this.query.end_stats_month
        this.dataList[2].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_month,
          this.query.end_stats_month,
        )
      } else if (this.query.date_type == '日') {
        param.start_stats_day = start_day = this.query.start_stats_day
        param.end_stats_day = end_day = this.query.end_stats_day
        this.dataList[2].value = this.$tool.filterTime(
          this.query.date_type,
          this.query.start_stats_day,
          this.query.end_stats_day,
        )
      }
      
      if(start_day == '' || start_day == undefined ){
        let date = new Date();
        // param.start_stats_year = param.end_stats_year = start_day = end_day = date.getFullYear()
        let MM = date.getMonth()+1;
        MM = MM<=9 ? '0'+MM : MM;
        param.start_stats_month =  start_day = date.getFullYear() + '01';
        param.end_stats_month =  end_day = date.getFullYear()+ MM;
      }
      param.date_type = this.$tool.dateType(start_day,end_day);
      api
        .hospital_used_day_timing(param)
        .then((res) => {
          let data = res.data,
            list = [],
            nameList = []
          this.dataList[0].value = data.province_name
          this.dataList[1].value = data.hospital_name
          data.testing_info.forEach((element) => {
            let obj = {
              name: element.testing_unique,
              data: element.cnt_list,
              type: 'line',
            }
            list.push(obj)
            nameList.push(element.testing_unique)
          })
          this.ehartData = {
            dataX: data.stats_time,
            dataY: list,
            nameList,
          }
          this.fullscreenLoading = false
        })
        .catch((err) => {
          this.fullscreenLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep .TJDetailMenu .box p:nth-child(2) {
  font-weight: bold;
}
.diagram {
  height: px(842);
  background-color: #fff;
  .diagrammax {
    display: flex;
    padding-top: px(15);
    font-size: px(16);
    font-weight: bold;
    color: #2d354c;
    margin-left: px(15);
  }
}
</style>
